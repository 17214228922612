import React from "react"
import { navigate } from "gatsby"
import Img from "gatsby-image"
import "./style.scss"

function Post (props) {

	const post = props.contentfulPost
	const date = new Date(post?.date)

	function goBack() {
		navigate(window.previousPath)
	}

  return (
    <article className="single-post">
			<div className="go-back" onClick={(e) => goBack(e)}><i className={"far fa-arrow-left"}></i> Go Back</div>
			<div className="image-main">
				{ post.image ?
					<Img
						fluid={post.image.fluid}
						objectFit="cover"
						objectPosition="50% 50%"
						fadeIn={false}
						durationFadeIn={2000}
						loading="eager"
					/>
				: null }
				{ post.logo ?
					<div className="logo-publication c0">
						<Img
							fluid={post.logo.fluid}
							objectFit="contain"
							objectPosition="50% 50%"
							fadeIn={false}
							durationFadeIn={2000}
							loading="eager"
						/>
					</div>
				: null }
				{ post.category ? <h6>{post.category[0].title}</h6> : null }
	  		</div>
			<div className="content-main">
				<div className="content-main-title">
					<h2>{post.title}</h2>
					{ date ? <h6 style={{marginTop: "-1em"}}>{date.toLocaleDateString(['en-US'], {weekday: 'long', month: 'long', day: 'numeric', year: 'numeric'})}</h6> : null }
				</div>
				<div className="content-main-padd" dangerouslySetInnerHTML={{__html: post.content.childMarkdownRemark.html}}>
				</div>
			</div>
    </article>
  )
}

export default Post