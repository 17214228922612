import React, {useEffect} from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Img from "gatsby-image"

import Post from "../components/Post/Post"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

export default ({ data, pageContext }) => {
  const post = data.contentfulPost

  return (
    <Post { ...data } />
  )
}
export const query = graphql`
  query($slug: String!) {
    contentfulPost( slug: { eq: $slug } ) {
      title
      date
      category {
        title
      }
      excerpt {
        excerpt
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      image {
        fluid (
          maxWidth: 2560,
          quality: 70,
        ) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      logo {
        fluid (
          maxWidth: 2560,
          quality: 70,
        ) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`